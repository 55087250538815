import React from "react"

import { FiTarget } from "react-icons/fi"
import { FaRegEye } from "react-icons/fa"

import { Container } from "reactstrap"

const Mission = props => {
  return (
    <div
      className="pt-5 pb-5"
      style={{
        backgroundColor: `#B7C7D0`,
      }}
    >
      <Container
        style={{
          color: `#2E2E2E`,
          padding: `30px`,
          height: `100%`,
          width: `100%`,
        }}
      >
        <div className="mission-vision-container">
          <div className="mission-vision-item">
            <h2 className="heading3"><FiTarget /><span>Mission</span></h2>
            <p className="pt-3">
              In support of our Corporate Vision, Fastlink is committed to
              provide a continuously growing wide array of Best of Breed ICT
              products and solutions to be supported by a Team of Principal
              Certified-Competent Workforce. Furthermore, it is also the
              company’s mission to provide Professionally Supported Services and
              Processes to generate the most customized, streamlined,
              value-engineered, and cost-effective Integrated Business-Based
              Solutions.
            </p>
          </div>
          <div className="line-center mission-vision-item"></div>
          <div className="mission-vision-item">
            <h2 className="heading3"><FaRegEye /><span>Vision</span></h2>
            <p className="pt-3">
              Fastlink envisions itself to be Philippines’ most Comprehensive
              and Innovative provider of ICT Solution by having and offering the
              widest Array of facilities and Customer Centric Services to
              address the market's dynamic and unique requirements. Fastlink
              strives to be the In Mind Solution Consultant and Partner for
              every ICT requirements, big or small, simple or complex,
              delivering delightful customer services for Long Term technology
              partnership.
            </p>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Mission
