import React from "react"

const Letters = ({ letter, description, setDescription, index }) => {
  return (
    <h2
      className={index === description ? "active" : ""}
      style={{ float: `left`, cursor: `pointer`, marginRight: `10px` }}
      onClick={() => {
        setDescription(index)
      }}
    >
      {letter}
    </h2>
  )
}

export default Letters
