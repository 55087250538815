import React from "react"
import { Container } from "reactstrap"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Competencies = () => {
  const CompeImage = useStaticQuery(graphql`
    query {
      competencies: file(relativePath: { eq: "about/competencies.png" }) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className="pt-5 pb-5" style={{ backgroundColor: `var(--FSC-YELLOW)` }}>
      <Container>
        <h2 className="heading1"><span>Competencies</span></h2>
        <div
          style={{
            maxWidth: `650px`,
            margin: `0 auto`,
            display: `block`,
          }}
        >
          <Img fluid={CompeImage.competencies.childImageSharp.fluid} />
        </div>
      </Container>
    </div>
  )
}

export default Competencies
