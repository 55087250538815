import React, { useState } from "react"
import { Container, Row, Col } from "reactstrap"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import value from "../../data/values.json"
import Letters from "./letters"

const Values = () => {
  const word = "FASTLINK"
  const tab = [...word]
  const [description, setDescription] = useState(0)

  const fastLink = tab.map((letter, index) => {
    return (
      // <div>
      <Letters
        letter={letter}
        key={index}
        description={description}
        setDescription={setDescription}
        index={index}
      />
      // </div>
    )
  })

  const values = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "about/values.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      className="pt-5 pb-5"
      style={{
        backgroundColor: `var(--FSC-WHITE)`,
      }}
    >
      <Container>
        <h2 className="heading1"><span>Corporate Values & Principles</span></h2>
        <Row className="pt-2">
          <Col lg="6">
            <Img
              fluid={values.image.childImageSharp.fluid}
              style={{ display: `block`, margin: `auto` }}
            />
          </Col>
          <Col lg="6">
            <div
              style={{
                display: `flex`,
                alignContent: `center`,
                paddingTop: '5vh'
                // backgroundColor: `red`,
              }}
            >
              <div style={{ display: `inline-block` }}>{fastLink}</div>
            </div>
            <div className="value">
              <h5>{value[description].value}</h5>
            </div>
            <p>{value[description].description}</p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Values
