import React from "react"
import Layout from "../Components/layout"
import Landing from "../Components/about/landing"
import History from "../Components/about/history"
import Mission from "../Components/about/mission"
import Values from "../Components/about/values"
import Competencies from "../Components/about/competencies"
// import Certifications from "../Components/about/certifications"
import SEO from "../Components/SEO"

export default function About() {
  return (
    <Layout>
      <SEO title="About Us" description="Fastlink Who We Are"></SEO>
      <div className="about">
        <Landing />
        <History />
        <Mission />
        <Values />
        <Competencies />
        {/* <Certifications /> */}
      </div>
    </Layout>
  )
}
