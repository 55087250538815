import React from "react"
import { Container } from "reactstrap"
import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"

const History = props => {
  useStaticQuery(graphql`
    query {
      history: file(relativePath: { eq: "about/people.png" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div
      className="pt-5 pb-5"
      style={{
        backgroundColor: `#F3F3F3`,
      }}
      id="history"
    >
      <Container
        style={{
          color: `#2E2E2E`,
          padding: `30px`,
          height: `100%`,
          width: `100%`,
        }}
      >
        <h1 className="mx-auto mb-4 heading1 p-3 text-center">
          <span>History</span>
        </h1>
        <p className="text-center">
        Fastlink was founded in 2001 to provide expertise and competence in the computing industry. Years of experience in servicing a variety of customers and projects has brought the company to a position to provide a strengthened reinforcement of Solution-Centric services. An evolution from Computing Competence, to Networking Competence, to Application Competence and ultimately to a seamless integration of multi product Solution addressing specific Customer Business needs has catapulted Fastlink to one of the country’s leading ICT Solutions providers. 
        </p>
      </Container>
    </div>
  )
}

export default History
