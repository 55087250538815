import React, { useState, useEffect } from "react"
import { BsChevronDown } from "react-icons/bs"
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql } from "gatsby"


import style from "./styles.module.css"

const Landing = props => {
  const [anchorTarget, setAnchorTarget] = useState(null)

  useEffect(() => {
    setAnchorTarget(document.getElementById("history"))
  })

  const handleClick = event => {
    event.preventDefault()
    anchorTarget.scrollIntoView({ behavior: "smooth", block: "start" })
  }

  const image = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "about/about_banner.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const backgroundstack = [
    image.banner.childImageSharp.fluid
  ].reverse()

  return (
    <BackgroundImage
      Tag="section"
      className={style.hero}
      fluid={backgroundstack}
      backgroundColor={`#040e18`}
    >
      <div>
        <h1>Why with us? </h1>
        <p>
          Fastlink prides itself with the competence in different global, best
          of breed ICT products and solutions. Having a wider variety of tried
          and tested integration of products allows Fastlink to carefully design the
          most fitting, cost-effective, and value-engineered solution that would
          address each and every customer's unique requirement. With a workforce that
          is exposed to multi-industry workflow experiences, coupled with the technical
          competence through Certification Trainings and Product Familiarity, Fastlink
          can guarantee only the best results and business solutions that are a LINK away.

          <a
            href="#history"
            onClick={handleClick}
            ariaLabel={`Scroll to #history`}
          >
            <BsChevronDown className={style.downIcon}></BsChevronDown>
          </a>

        </p>

      </div>
    </BackgroundImage>
  )
}

export default Landing
